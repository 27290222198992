import React, { useContext, useEffect, useState } from 'react';
import AnimatedCursor from "react-animated-cursor";
import Context from '../../utils/context/Context';

const Cursor = () => {
    const { vwidth } = useContext(Context);
    const [visibsle, setvisibale] = useState(true);
    useEffect(() => {
        if (vwidth < 767) {
            setvisibale(false);
        }
    }, []);
    return (
        <>
            {visibsle === true ?
                <AnimatedCursor
                    innerSize={8}
                    outerSize={40}
                    color={'255, 186, 46'}
                    outerAlpha={0.4}
                    innerScale={2}
                    outerScale={3}
                    showSystemCursor={false}
                    trailingSpeed={8} />
                :
                ""}

        </>
    );
}

export default Cursor;
