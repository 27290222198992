import React, { useContext, useEffect } from 'react';
import Context from '../utils/context/Context';
import Cursor from '../components/mouse/Cursor';
import { useNavigate } from 'react-router-dom';

const Error = () => {
    const navigate = useNavigate();
    const { preloader, setpreloader } = useContext(Context);
    const  home = ()=>{
        navigate(`/home`);
    }
    useEffect(() => {
        if (preloader === true) {
            setTimeout(() => {
                setpreloader(false);
            }, 2000);
        } else {
            setpreloader(false);
        }
    }, []);
    return (
        <div className='all-center errorpage'>
            <Cursor/>
            <div className='sub-head'>
                <h2 className='page-head center'>Page Not Found</h2>
                <p className='head-para center'>The page you are looking for is not found !</p>
                <div className="all-center">
                    <button className="action-btn all-center" onClick={() => { home() }}>
                        <p>Home</p>
                    </button>
                </div>
            </div>

        </div>
    );
}
export default Error;
