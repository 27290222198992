// import Logo from "./src/images/logo.svg";
import Logo from "./src/images/aaa.svg"
import Amit from "./src/images/about/amit.webp";
import Menu from './src/images/icons/menu.svg';
import Close from './src/images/icons/close.svg';
import Banner from "./src/images/page/banner.webp";
import Alink from "./src/images/icons/arrow-link.png";
import Alinky from "./src/images/icons/arrow-link-yellow.png";
import Arrowr from "./src/images/icons/arrow-right.svg";    
import Arrowg from "./src/images/icons/arrow-gray.svg";
import Preloader from "./src/images/preloader.gif";
import Light from "./src/images/icons/light.png";
import Dark from "./src/images/icons/dark.png";
import prev from "./src/images/icons/Frame 154.svg";
import back from "./src/images/icons/Frame 155.svg";
// projects
import Project from './src/images/projects/project.png';
import P1 from './src/images/projects/p1.webp';
import P2 from './src/images/projects/p2.webp';
import P3 from './src/images/projects/p3.webp';
import b1 from './src/images/projects/b1.webp';
import b2 from './src/images/projects/b2.webp';
import b3 from './src/images/projects/b3.webp';
import left from "./src/images/arrow-left.png";
import right from "./src/images/icons/arrow-right.png"

// Dark 
import Darklogo from './src/images/darklogo.webp';
import Dlink from './src/images/icons/dark-arrow-link.png';
import Darrowr from './src/images/icons/d-arrow-right.svg';
import Dlink2 from './src/images/icons/dark-arrow-link-2.png';
import Dpreloader from './src/images/darkpreloader.gif';
import main from "./src/images/projects/mainBang.png"

const Base = {
    // home: '/',
    logo: Logo,
    menu: Menu,
    banner: Banner,
    alink: Alink,
    alinky: Alinky,
    close: Close,
    project: Project,
    arrowr: Arrowr,
    arrowg: Arrowg,
    amit: Amit,
    preloader: Preloader,
    p1: P1,
    p2: P2,
    p3: P3,
    b1: b1,
    b2: b2,
    b3: b3,
    dark: Dark,
    light: Light,
    darklogo: Darklogo,
    dlink: Dlink,
    dlink2: Dlink2,
    darrowr: Darrowr,
    dpreloader: Dpreloader,
    prev :  prev ,
    back: back,
    left : left,
    right : right,
    main : main
  };
  
  export default Base;