import React, { useContext, useEffect, useRef } from 'react';
import $ from 'jquery';
import Cursor from '../components/mouse/Cursor';
import Base from '../../config';
import "../css/section.css";
import Context from '../utils/context/Context';
import Pagenum from '../components/pagecount/Pagenum';
import Projectcard from './projectcards/Projectcard';
import ProjectDataList from '../data/ProjectDataList';
import Back from '../components/back/Back';

const Projects = () => {
    const { setpage, preloader, setpreloader, responsive, wsmode } = useContext(Context);
    const containerRef = useRef(null);

    const handleScroll = (event) => {
        const delta = Math.max(-1, Math.min(1, event.deltaY)); // Get the direction of the scroll
        containerRef.current.scrollLeft += delta * 600; // Adjust the scroll position based on the scroll direction
    };



    const load = () => {
        setTimeout(() => {
            $('.page-direction').removeClass("pos-bottom");
            $('.projects-section').css({ right: "0vw" });
            $('.mob-project-left').css({ left: "0vw" });
            $('.mob-project-right').css({ right: "0vw" });
        }, 100);
    }

    useEffect(() => {
        setpage("projects");
        if (preloader === true) {
            setTimeout(() => {
                setpreloader(false);
                load();
            }, 2000);
        } else {
            setpreloader(false);
            load();
        }
    }, []);
    return (
        <>
            {responsive === false ?
                <>
                    <div className="container-cursor">

                        <Cursor />
                    </div>
                    {/* page number */}
                    <Pagenum num="02" />
                    {/* page number */}




                    <div className="projects-section relative">
                        <div className='head-back'><Back />
                            <h2 className='page-head'>Projects</h2></div>



                        <div className='project-carousle flex-align' ref={containerRef} onWheel={handleScroll}>
                            {ProjectDataList.map((p) => (
                                <Projectcard key={p.id} name={p.name} year={p.year} place={p.place} image={p.image_path} link={p.link} />
                            ))}
                        </div>
                    </div>

                    {/* direction message */}
                    <div className='page-direction flex-align pos-bottom'>
                        <p className='page-direction-p' >Scroll on slider</p>
                        <img className='page-direction-icon' src={wsmode ? Base.arrowr : Base.darrowr} alt='arrow' />
                    </div>
                    {/* direction message */}

                </>
                :
                <>
                    <div className="mob-box">
                        <div className="relative">
                            <h2 className='page-head mob-project-left '>Projects</h2>
                        </div>
                        {ProjectDataList.map((p, index) => (
                            <div className="relative" key={index}>
                                <div className={index % 2 === 0 ? "mob-project-left" : "mob-project-right"}>
                                    <Projectcard key={p.id} name={p.name} year={p.year} place={p.place} image={p.image_path} link={p.link} />
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            }
        </>

    );
}

export default Projects;
