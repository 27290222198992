import React from 'react';

const Pagenum = (props) => {
    return (
        <>
            {/* page number */}
            <div className="page-num">
                <p className="p-num">{props.num}</p>
            </div>
            {/* page number */}
        </>
    );
}

export default Pagenum;
