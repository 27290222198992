import React, { useContext } from 'react';
import Base from '../../config'
import '../css/header.css';
import '../css/menu.css';

import Context from '../utils/context/Context';
import { useNavigate } from 'react-router-dom';

const Menu = () => {
    const {menu, setmenu, page, resetpos, setpagename} = useContext(Context);
    const navigate = useNavigate();
    return (
        <>
            <div className='menu' style={{"top": menu === false ? "100vh" : "0vh"}}>
                <div className="menu-box">
                    <div className="flex-align space-between">
                        <div className="head-left">
                        </div>
                        <div className="head-right">
                            <button className="head-menu flex-align" onClick={()=>{setmenu(false)}}>
                                <p className="menu-text">Close</p>
                                <img className="menu-icon" src={Base.close} alt="menu" />
                            </button>
                        </div>
                    </div>
                </div>
                
                {/* menu links */}
                <div className='menu-link'>
                    <div className=''><button className='menu-btn' style={{"color":page === "home" ? "#FFB82E" : ""}} onClick={()=>{setmenu(false); resetpos("home")}}>Home</button></div>
                    <div className=''><button className='menu-btn' style={{"color":page === "projects" ? "#FFB82E" : ""}} onClick={()=>{setmenu(false); resetpos("projects")}}>Projects</button></div>
                    <div className=''><button className='menu-btn' style={{"color":page === "clients" ? "#FFB82E" : ""}} onClick={()=>{setmenu(false); resetpos("ourclients")}}>Corporate Clients</button></div>
                    <div className=''><button className='menu-btn' style={{"color":page === "about" ? "#FFB82E" : ""}} onClick={()=>{setmenu(false); resetpos("about")}}>About</button></div>
                    <div className=''><button className='menu-btn' style={{"color":page === "contact" ? "#FFB82E" : ""}} onClick={()=>{setmenu(false); resetpos("contact")}}>Contact</button></div>
                    <div className=''><button className='menu-btn' style={{"color":page === "Client" ? "#FFB82E" : ""}} onClick={()=>{setmenu(false); resetpos("clients")}}>Clients Review</button></div>
                </div>
                {/* menu links */}

                {/* social media links */}
                <div className='menu-social-links flex-align'>
                <button className='flex-align connect-div' onClick={()=>{window.open("https://www.facebook.com/er.amitagrawal", '_blank');}}><p className='project-link connect-head'><span className='menu-social-p'>facebook</span></p><img className="project-card-arrow" src={Base.arrowg} alt="arrow" /></button>
                <button className='flex-align connect-div' onClick={()=>{window.open("https://www.instagram.com/er.amitagrawal?igsh=NWNlMHNtNm1kdjg5", '_blank');}}><p className='project-link connect-head'><span className='menu-social-p'>instagram</span></p><img className="project-card-arrow" src={Base.arrowg} alt="arrow" /></button>
                <button className='flex-align connect-div' onClick={()=>{window.open("https://www.linkedin.com/in/amit-agrawal-3213bab?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app", '_blank');}}><p className='project-link connect-head'><span className='menu-social-p'>linkedin</span></p><img className="project-card-arrow" src={Base.arrowg} alt="arrow" /></button>
                </div>
                {/* social media links */}

            </div>
        </>
    );
}

export default Menu;
