import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Base from '../../../config';

const CarouselComp = () => {
    return (
        <>
            <Carousel autoPlay={true} infiniteLoop={true} showIndicators={true} showThumbs={false} stopOnHover={false} useKeyboardArrows={true}>
                <div onClick={()=>{console.log("clicking on carousel")}}>
                    <img className='w-100' src={Base.banner} />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div onClick={()=>{console.log("clicking on carousel")}}>
                    <img className='w-100' src={Base.banner} />
                    {/* <p className="legend">Legend 2</p> */}
                </div>
                <div onClick={()=>{console.log("clicking on carousel")}}>
                    <img className='w-100' src={Base.banner} />
                    {/* <p className="legend">Legend 3</p> */}
                </div>
            </Carousel>
        </>
    );
}

export default CarouselComp;
