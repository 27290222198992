import React, { useContext, useEffect, useState } from 'react';
import $ from 'jquery';
import Cursor from '../components/mouse/Cursor';
import Context from '../utils/context/Context';
import Pagenum from '../components/pagecount/Pagenum';
import Base from '../../config';
import { Row, Col } from 'react-grid-system';
import { ThreeDots } from 'react-loader-spinner';

import emailjs from '@emailjs/browser';

const Contact = () => {
    const { setpage, preloader, setpreloader, responsive, wsmode } = useContext(Context);

    // validitions
    const [name, setname] = useState("");
    const [Cemail, setCemail] = useState("");
    const [msg, setmsg] = useState("");

    const [vname, setvname] = useState(true);
    const [vCemail, setvCemail] = useState(true);
    const [vmsg, setvmsg] = useState(true);

    const [mailed, setmailed] = useState("Submit");

    const load = () => {
        setTimeout(() => {
            $('.contact-top').css({ left: "0" });
            $('.contact-second').css({ right: "0" });
        }, 100);
    }

    // email send
    const validname = () => {
        // name
        if (name.length < 30) {
            setvname(true);
        } else {
            setvname(false);
        }
    };
    const validemail = () => {
        // email
        var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(Cemail)) {
            setvCemail(false);
        } else {
            setvCemail(true);
        }
    }
    const validmsg = () => {
        // msg
        if (msg.length < 80) {
            setvmsg(true);
        } else {
            setvmsg(false);
        }
    }

    const valid = () => {
        if (name.length < 3) {
            setvname(false)
        }else if(Cemail.length < 5) {
            setvCemail(false)
        }else if (msg.length < 5) {
            setvmsg(false)
        }else if (vname === vCemail === vmsg === true) {
            sendmail();
        } else {
            console.log("form not valid")
        }
    }

    const sendmail = () => {
        setmailed(
            <ThreeDots
                visible={true}
                height="1.05vw"
                // width="80"
                color="#727272"
                radius="9"
                ariaLabel="three-dots-loading"
            />
        )
        const templateParams = {
            name: name,
            msg: msg,
            email: Cemail
        };

        emailjs
            .send('service_1jgpi6l', 'template_dwy90cs', templateParams, {
                publicKey: 'lQNlSU9ANvaWj2Rsg',
            })
            .then(
                (response) => {
                    console.log('SUCCESS!', response.status, response.text);
                    setmailed("Sent");
                    setTimeout(() => {
                        setmailed("Submit");
                        setname("");
                        setCemail("");
                        setmsg("");
                    }, 3000);
                },
                (err) => {
                    console.log('FAILED...', err);
                    setmailed("Failed");
                    setTimeout(() => {
                        setmailed("Submit");
                    }, 2000);
                },
            );
    }


    useEffect(() => {
        setpage("contact");
        if (preloader === true) {
            setTimeout(() => {
                setpreloader(false);
                load();
            }, 2000);
        } else {
            setpreloader(false);
            load();
        }
    }, []);
    return (
        <>
            {
                responsive === false ?
                    <>
                        <Cursor />
                        <Pagenum num="05" />
                        <div className='about-section'>
                            <div className='sub-head relative contact-top'>
                                <h2 className='page-head'>Connect with us</h2>
                                <p className='head-para'>For inquiries about our services in hospitals, hotels, schools, and high-end residences.</p>
                            </div>
                            
                            <div className='connection-links relative contact-second'>
                                <Row>
                                    <Col sm={6}>
                                        <div className='contact-form'>
                                            <input
                                                className={vname === true ? 'inputs' : 'inputs inputs-error'}
                                                type="text"
                                                value={name}
                                                onChange={(e) => { setname(e.target.value); validname(); }}
                                                placeholder="Name"
                                            />
                                            <input
                                                className={vCemail === true ? 'inputs' : 'inputs inputs-error'}
                                                type="text"
                                                value={Cemail}
                                                onChange={(e) => { setCemail(e.target.value); validemail(); }}
                                                placeholder="Email"
                                            />
                                            <textarea
                                                className={vmsg === true ? 'inputs lastinput' : 'inputs lastinput inputs-error'}
                                                type="text"
                                                value={msg}
                                                onChange={(e) => { setmsg(e.target.value); validmsg(); }}
                                                placeholder="Message"
                                                rows={4}
                                            />
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3718.5220923839165!2d81.6414667!3d21.2507916!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a28dd9dee1e19bd%3A0x63a09f8c8d800faf!2sHira%20Arcade%2C%20Pandri%2C%20Raipur%2C%20Chhattisgarh%20492001!5e0!3m2!1sen!2sin!4v1711194023940!5m2!1sen!2sin"
                                            width="100%"
                                            height="100%"
                                            title="Embedded Content"
                                            allowFullScreen
                                            frameBorder="0"
                                        />
                                    </Col>
                                </Row>
                                <div className="flex">
                                    <button className="action-btn all-center" onClick={() => { valid() }}>
                                        <p>{mailed}</p>
                                    </button>
                                </div>
                            </div>

                            <div className='connection-links relative contact-second'>
                                <button className='connect-link' onClick={() => { window.open("https://maps.app.goo.gl/LiDyutA9bVF8PXsx7", '_blank'); }}>
                                    <div className='flex-align connect-div'>
                                    <p className='project-link connect-head'>Address</p>
                                    <img className="project-card-arrow" src={wsmode ? Base.alink : Base.dlink2} alt="arrow" />
                                    </div>
                                    <p className='head-para contact-para'>Ground floor, Hira arcade, Raipur (C.G)</p>
                                </button>
                                <button className='connect-link' onClick={() => { window.open("tel:${07714087064}") }}>
                                    <div className='flex-align connect-div'><p className='project-link connect-head'>Phone</p><img className="project-card-arrow" src={wsmode ? Base.alink : Base.dlink2} alt="arrow" /></div>
                                    <p className='head-para contact-para'>0771-408-7064</p>
                                </button>
                                <button className='connect-link' onClick={() => { window.open("mailto:er.amitagrawal@gmail.com") }}>
                                    <div className='flex-align connect-div'><p className='project-link connect-head'>Email</p><img className="project-card-arrow" src={wsmode ? Base.alink : Base.dlink2} alt="arrow" /></div>
                                    <p className='head-para contact-para'>er.amitagrawal@gmail.com</p>
                                </button>
                            </div>
                        </div></>
                    :
                    <>
                        <div className='sub-head relative contact-top'>
                            <h2 className='page-head'>Connect with us</h2>
                            <p className='head-para'>For inquiries about our services in hospitals, hotels, schools, and high-end residences.</p>
                        </div>
                        
                        <div className='connection-links relative contact-second'>
                            <div className='contact-form'>
                                <input
                                    className={vname === true ? 'inputs' : 'inputs inputs-error'}
                                    type="text"
                                    value={name}
                                    onChange={(e) => { setname(e.target.value); validname(); }}
                                    placeholder="Name"
                                />
                                <input
                                    className={vCemail === true ? 'inputs' : 'inputs inputs-error'}
                                    type="text"
                                    value={Cemail}
                                    onChange={(e) => { setCemail(e.target.value); validemail(); }}
                                    placeholder="Email"
                                />
                                <textarea
                                    className={vmsg === true ? 'inputs lastinput' : 'inputs lastinput inputs-error'}
                                    type="text"
                                    value={msg}
                                    onChange={(e) => { setmsg(e.target.value); validmsg(); }}
                                    placeholder="Message"
                                    rows={4}
                                />
                            </div>
                            <div className="flex">
                                <button className="action-btn all-center" onClick={() => { valid() }}>
                                    <p>{mailed}</p>
                                </button>
                            </div>
                            <div className='mob-map'>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3718.5220923839165!2d81.6414667!3d21.2507916!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a28dd9dee1e19bd%3A0x63a09f8c8d800faf!2sHira%20Arcade%2C%20Pandri%2C%20Raipur%2C%20Chhattisgarh%20492001!5e0!3m2!1sen!2sin!4v1711194023940!5m2!1sen!2sin"
                                    width="100%"
                                    height="100%"
                                    title="Embedded Content"
                                    allowFullScreen
                                    frameBorder="0"
                                />
                            </div>
                        </div>

                        <div className='connection-links relative contact-second'>
                            <button className='connect-link' onClick={() => { window.open("https://maps.app.goo.gl/LiDyutA9bVF8PXsx7", '_blank'); }}>
                                <div className='flex-align connect-div'><p className='project-link connect-head'>Address</p><img className="project-card-arrow" src={wsmode ? Base.alink : Base.dlink} alt="arrow" /></div>
                                <p className='head-para'>Ground floor, Hira arcade, Raipur (C.G)</p>
                            </button>
                            <button className='connect-link' onClick={() => { window.open("tel:${07714087064}") }}>
                                <div className='flex-align connect-div'><p className='project-link connect-head'>Phone</p><img className="project-card-arrow" src={wsmode ? Base.alink : Base.dlink} alt="arrow" /></div>
                                <p className='head-para'>0771-408-7064</p>
                            </button>
                            <button className='connect-link' onClick={() => { window.open("mailto:er.amitagrawal@gmail.com") }}>
                                <div className='flex-align connect-div'><p className='project-link connect-head'>Email</p><img className="project-card-arrow" src={wsmode ? Base.alink : Base.dlink} alt="arrow" /></div>
                                <p className='head-para'>er.amitagrawal@gmail.com</p>
                            </button>
                        </div>
                    </>
            }
        </>
    );
}

export default Contact;
