import React from 'react'
import copyright from "../images/copyright.png"
import "../css/footer.css"
import { Col, Row } from 'react-grid-system'
import { Link } from 'react-router-dom'



export default function Footer() {
  return (
    <>
      <div className="footer">
        <Row>
          <Col sm={4}></Col>
          <Col sm={4}>
            <div className="footerbase">
              <img src={copyright} alt="Copyright" />
              <p className="footer-text">amitagrawal.design</p>
            </div>
          </Col>
          <Col sm={4}>
            <div className="footer-nav">
              <ul>
                <li><Link className='na' to="/home">Home</Link></li>
                <li><Link className='na' to="/about">About</Link></li>
                <li><Link className='na' to="/contact">Contact</Link></li>
                <li><Link className='na' to="/ourclients">Work</Link></li>
              </ul>
            </div>
          </Col>
        </Row>




      </div>


    </>


  )
}
