import React, { useContext, useEffect } from 'react';
import $ from 'jquery';
import Menu from './Menu';
import Preloader from './Preloader';
import Base from '../../config'
import "../css/header.css";

import Context from '../utils/context/Context';
import { useEffect } from 'react/cjs/react.production.min';

export default function Header() {
  const { setmenu, resetpos, preloader, setwsmode, wsmode, setMode } = useContext(Context);

  const Mode = () => {
    if (wsmode === true) {
      setwsmode(false);
      $(".mode-icon").css({ left: "2.6vw" });
      setMode();
    } else {
      setwsmode(true);
      $(".mode-icon").css({ left: "0.5vw" });
      setMode();
    }
  }

  // useEffect(()=>{
  //   if(wsmode === null){}else{
  //     if(theme === "Light"){
  //       $(".mode-icon").css({ left: "0.5vw" });
  //     }else{
  //       $(".mode-icon").css({ left: "2.6vw" });
  //     }
  //   }
  // },[])

  return (
    <>
      {preloader ? <Preloader /> : ""}

      <Menu />
      <div className="header">
        <div className="flex-align space-between">
          <div className="head-left">
            <img className="head-logo" src={wsmode ? Base.logo : Base.darklogo} alt="Amit Agrawal" onClick={() => { resetpos("home") }} />
          </div>
          <div className="head-right flex-align">
            <button className={`ws-mode ${wsmode === true ? "ws-colorL" : "ws-colorD "} flex-align`} onClick={() => { Mode() }}>
              <img className={`mode-icon ${wsmode === true ? 'mode-iconL' : 'mode-iconD'}`} src={wsmode === true ? Base.light : Base.dark} alt="mode" />
            </button>
            <img className={`mob-mode-icon`} src={wsmode === true ? Base.light : Base.dark} alt="mode" onClick={() => { Mode() }} />
            <button className="head-menu flex-align" onClick={() => { setmenu(true) }}>
              <p className="menu-text">Menu</p>
              <img className="menu-icon" src={Base.menu} alt="menu" />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
