import React, { useContext, useEffect} from 'react';
import $ from 'jquery';
import Cursor from '../components/mouse/Cursor';
import Base from '../../config';
import "../css/section.css";
import CountUp from 'react-countup';
import { TypeAnimation } from 'react-type-animation';
import Context from '../utils/context/Context';
import Pagenum from '../components/pagecount/Pagenum';
import CarouselComp from './carousel/CarouselComp';
// import Footer from '../includes/Footer';
// import copyright from "../images/copyright.png"


const Dashboard = () => {
    const { setpage, resetpos, preloader, setpreloader, responsive, vwidth, wsmode } = useContext(Context);

    const load = () => {
        setTimeout(() => {
            $('.home-left').removeClass("pos-right");
            $('.headset').removeClass("pos-left");
            $('.mob-headset').css({ left: 0 });
            $('.mob-counter').css({ right: 0 });
            $('.mob-banner').css({ right: 0 });
            $('.mob-footer').css({ right: 0 });
        }, 100);
    }

    const loadmob = () => {
        $('.mob-headset').css({ left: 0 });
        $('.mob-counter').css({ right: 0 });
        $('.mob-banner').css({ right: 0 });
        $('.mob-footer').css({ right: 0 });
    }

    useEffect(() => {
        setpage("home");
        if (preloader === true) {
            setTimeout(() => {
                setpreloader(false);
                if (vwidth > 767) {
                    load()
                } else {
                    loadmob()
                }
            }, 2000);
        } else {
            setpreloader(false);
            load();
        }
    }, []);

    // these are page components
    const Banner = () => {
        return (
            <div className='home-banner'>
                <CarouselComp />
                {/* <img className="w-100" src={Base.banner} alt="banner" /> */}
            </div>
        )
    }
    const Social = () => {
        return (
            <div className="social-links">
                <button className="social-link flex-align" onClick={() => { window.open("https://www.facebook.com/er.amitagrawal", '_blank'); }}>
                    <img className="link-arrow" src={wsmode ? Base.alink : Base.dlink} alt="link" />
                    <p className="link-p">facebook</p>
                </button>
                <button className="social-link flex-align" onClick={() => { window.open("https://www.instagram.com/er.amitagrawal?igsh=NWNlMHNtNm1kdjg5", '_blank'); }}>
                    <img className="link-arrow" src={wsmode ? Base.alink : Base.dlink} alt="link" />
                    <p className="link-p">instagram</p>
                </button>
                <button className="social-link flex-align" onClick={() => { window.open("https://www.linkedin.com/in/amit-agrawal-3213bab?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app", '_blank'); }}>
                    <img className="link-arrow" src={wsmode ? Base.alink : Base.dlink} alt="link" />
                    <p className="link-p">linkedin</p>
                </button>
            </div>
        )
    }
    const Counter = () => {
        return (
            <div className="counter-section flex-align">
                <div className="counter-box">
                    <p className="counter-num"><CountUp end={500} duration={8} />+</p>
                    <p className="counter-text">Projects Completed</p>
                </div>
                <div className="counter-box">
                    <p className="counter-num"><CountUp end={25} duration={8} />+</p>
                    <p className="counter-text">Years of Experience</p>
                </div>
            </div>
        )
    }

    const Headset = () => {
        return (
            <>
                <p className="mini-head">
                    <TypeAnimation
                        sequence={[
                            'Designer + Engineer',   
                            300,
                            
                           
                            '',
                            300,
                        ]}
                        wrapper="span"
                        speed={50}
                        repeat={Infinity}
                    />
                </p>
                <h1 className="main-head">Designing spaces for tomorrow</h1>
                <p className="head-para invert-p">Our forward-thinking approach ensures environments that inspire and adapt to evolving demands.</p>
                <div className="flex">
                   
                    <button className="action-btn all-center" onClick={() => { resetpos("projects") }}>
                        <p>Projects</p>
                    </button>

                </div>
            </>
        )
    }
    // these are page components ends
    return (
        <>
            {responsive === false ?
                <>
                    <Cursor />
                    <div className="home-left pos-right">
                        <Banner />

                        {/* social links */}
                        <Social />
                        {/* social links */}

                        {/* counter */}
                        <Counter />
                        {/* counter */}

                    </div>

                    {/* page number */}
                    <Pagenum num="01" />
                    {/* page number */}

                    {/* headset */}
                    <div className="headset pos-left">
                        <Headset />
                    </div>
                    {/* headset */}

                   
                   
                </>
                :
                <>
                    <div className="relative">
                        <div className="mob-banner">
                            <Banner />
                        </div>
                    </div>
                    {/* headset */}
                    <div className="relative">
                        <div className="mob-headset">
                            <Headset />
                        </div>
                    </div>
                    {/* headset */}
                    <div className="relative">
                        <div className="mob-counter">
                            <Counter />
                        </div>
                    </div>
                    {/* <div className="relative">
                        <div className="mob-footer">
                            <Footer />
                        </div>
                    </div> */}

                </>
            }
        </>
    );
}

export default Dashboard;
