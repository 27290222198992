import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import  "../../css/back.css"
import Base from "../../../config"
import Context from '../../utils/context/Context';


const Back = () => {
  const navigate = useNavigate();
  const { wsmode } = useContext(Context);

  return (
    <div className="container-back">
      <button onClick={() => navigate(-1)} className="back-btn">
        <img  src={wsmode ? Base.left : Base.right}  alt="Back arrow" />
        <p style={{ color: '#313131' }}></p>
      </button>
    </div>
  );
};

export default Back;
