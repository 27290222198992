import React, { useContext, useEffect } from 'react';
import $ from 'jquery';
import Cursor from '../components/mouse/Cursor';
import Context from '../utils/context/Context';
import Base from '../../config';

import Pagenum from '../components/pagecount/Pagenum';

const About = () => {
    const { setpage, preloader, setpreloader, responsive } = useContext(Context);

    const load = () => {
        setTimeout(() => {
            $('.about-left').css({ left: "0" });
            $('.about-right').css({ right: "0" });
        }, 100);
    }
    useEffect(() => {
        setpage("about");
        if (preloader === true) {
            setTimeout(() => {
                setpreloader(false);
                load();
            }, 2000);
        } else {
            setpreloader(false);
            load();
        }
    }, []);
    return (
        <>
            {responsive === false ?
                <>
                    <div>
                        <Cursor />
                        <Pagenum num="04" />
                        <div className='about-section flex-align space-between'>
                            <div className='about-left relative'>
                                <div className=''>
                                    <h2 className='page-head'>Our values</h2>
                                    <p className='head-para' style={{marginBottom:"40px"}}>Our architectural design philosophy blends innovative aesthetics with practical functionality. We prioritize user-centric solutions, sustainability, and seamless integration into the environment. Committed to excellence, we create timeless spaces that inspire and endure.</p>
                                </div>
                                <div className=''>
                                    <h2 className='page-head' style={{fontSize:"25px"}}>Principal Engineer</h2>
                                    <p className='head-para'>Amit Agrawal is a visionary interior designer renowned for his exceptional creativity and meticulous attention to detail. With a passion for transforming spaces into captivating environments, Amit brings a wealth of expertise and a fresh perspective to every project he undertakes. His ability to seamlessly blend aesthetics with practicality ensures that each space he designs is not only visually stunning but also tailored to meet the unique needs and preferences of his clients.</p>
                                </div>
                                <div className='about-get'>
                                    <p className='about-yhead'>Get in touch</p>
                                    <p className='head-para'>For inquiries, collaborations, or just to say hello, feel free to reach out via <a className='contact-to' href="mailto:er.amitagrawal@gmail.com">er.amitagrawal@gmail.com</a></p>
                                </div>
                            </div>
                            <div className='about-right relative'>
                                <img className='amit' src={Base.amit} alt="amit agrawal" />
                            </div>
                        </div>
                    </div>
                </>
                :
                <div className="mob-box">
                    <div className='about-right relative'>
                        <img className='amit' src={Base.amit} alt="amit agrawal" />
                    </div>
                    <div className='about-left relative'>
                        <div className=''>
                            <h2 className='page-head'>Where Imagination Meets Structure</h2>  ``
                            <p className='head-para'>Amit Agrawal is a visionary interior designer renowned for his exceptional creativity and meticulous attention to detail. With a passion for transforming spaces into captivating environments, Amit brings a wealth of expertise and a fresh perspective to every project he undertakes. His ability to seamlessly blend aesthetics with practicality ensures that each space he designs is not only visually stunning but also tailored to meet the unique needs and preferences of his clients.</p>
                        </div>
                        <div className='about-get'>
                            <p className='about-yhead'>Get in touch</p>
                            <p className='head-para'>For inquiries, collaborations, or just to say hello, feel free to reach out via <a className='contact-to' href="mailto:er.amitagrawal@gmail.com">er.amitagrawal@gmail.com</a></p>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default About;
