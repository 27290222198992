import I1 from '../images/projects/Banglow1/i1.webp';
import I2 from '../images/projects/Banglow1/i2.webp';
import I3 from '../images/projects/Banglow1/i3.webp';
import I4 from '../images/projects/Banglow1/i4.webp';
import I5 from '../images/projects/Banglow1/i5.webp';
import I6 from '../images/projects/Banglow1/i6.webp';
import I7 from '../images/projects/Banglow1/i7.webp';
import I8 from '../images/projects/Banglow1/i8.webp';
import I9 from '../images/projects/Banglow1/i9.webp';
import I10 from '../images/projects/Banglow1/i10.webp';
import I11 from '../images/projects/Banglow1/i11.webp';
import I12 from '../images/projects/Banglow1/i12.webp';
import I13 from '../images/projects/Banglow1/i13.webp';
import I14 from '../images/projects/Banglow1/i14.webp';
import I15 from '../images/projects/Banglow1/i15.webp';
import I16 from '../images/projects/Banglow1/i16.webp';
import I17 from '../images/projects/Banglow1/i17.webp';
import I18 from '../images/projects/Banglow1/i18.webp';

import M1 from '../images/projects/Banglow2/m1.webp';
import M2 from '../images/projects/Banglow2/m2.webp';
import M3 from '../images/projects/Banglow2/m3.webp';
import M4 from '../images/projects/Banglow2/m4.webp';
import M5 from '../images/projects/Banglow2/m5.webp';
import M6 from '../images/projects/Banglow2/m6.webp';
import M7 from '../images/projects/Banglow2/m7.webp';
import M8 from '../images/projects/Banglow2/m8.webp';
import M9 from '../images/projects/Banglow2/m9.webp';
import M10 from '../images/projects/Banglow2/m10.webp';
import M11 from '../images/projects/Banglow2/m11.webp';
import M12 from '../images/projects/Banglow2/m12.webp';
import M13 from '../images/projects/Banglow2/m13.webp';
import M14 from '../images/projects/Banglow2/m14.webp';
import M15 from '../images/projects/Banglow2/m15.webp';

import C1 from '../images/projects/corp/mark.webp';
import C2 from '../images/projects/corp/brilliant.webp';
import C3 from '../images/projects/corp/radiant.webp';
import C4 from '../images/projects/corp/vardaan.webp';

const Pimg = {
    'bhajjankas-mansions-count': 18,
    'bhajjankas-mansions-1':I1,'bhajjankas-mansions-2':I2,'bhajjankas-mansions-3':I3,'bhajjankas-mansions-4':I4,'bhajjankas-mansions-5':I5,'bhajjankas-mansions-6':I6,'bhajjankas-mansions-7':I7,'bhajjankas-mansions-8':I8,'bhajjankas-mansions-9':I9,'bhajjankas-mansions-10':I10,'bhajjankas-mansions-11':I11,'bhajjankas-mansions-12':I12,'bhajjankas-mansions-13':I13,'bhajjankas-mansions-14':I14,'bhajjankas-mansions-15':I15,'bhajjankas-mansions-16':I16,'bhajjankas-mansions-17':I17,'bhajjankas-mansions-18':I18,
    
    'swarnbhoomi-count': 15,
    'swarnbhoomi-1':M1,'swarnbhoomi-2':M2,'swarnbhoomi-3':M3,'swarnbhoomi-4':M4,'swarnbhoomi-5':M5,'swarnbhoomi-6':M6,'swarnbhoomi-7':M7,'swarnbhoomi-8':M8,'swarnbhoomi-9':M9,'swarnbhoomi-10':M10,'swarnbhoomi-11':M11,'swarnbhoomi-12':M12,'swarnbhoomi-13':M13,'swarnbhoomi-14':M14,'swarnbhoomi-15':M15,
    
    Corporate_Clients_count: 4,
    Corporate_Clients1:C1,
    Corporate_Clients2:C2,
    Corporate_Clients3:C3,
    Corporate_Clients4:C4,
  };
  
  export default Pimg;