import React from 'react';
import Base from '../../../config';
import '../../css/section.css';
import { useNavigate } from 'react-router-dom';
import "../../css/card.css"
import amit from "../../images/amitlogo.svg"

const Projectcard = (props) => {
    const navigate = useNavigate();
    const {name, year, place, image, link} = props;
    const open = (l)=>{
        navigate(`/project/${l}`);
    }
    return (
        <div className="project-card">
            <div className="image-container">
                <img className='project-img' src={image} alt="project" />
                <img className="watermark" src={amit} />
            </div>
            <div className='flex-align space-between'>
                <h3 className='project-card-name'>{name}</h3>
                <p className='project-card-year'>{year}</p>
            </div>
            <p className='project-card-place'>{place}</p>
            <button className='flex-align project-card-btn' onClick={()=>{open(link)}}>
                <p className='project-link'>See project</p>
                <img className="project-card-arrow" src={Base.alinky} alt="arrow" />
            </button>
        </div>
    );
}

export default Projectcard;
