import React from 'react';
import Maincontext from './utils/context/MainContext';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Body from './Body';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Project from './pages/Project';
import About from './pages/About';
import Contact from './pages/Contact';
import OurClients from './pages/OurClients';
import Error from './pages/Error';
import ComingSoon from './pages/comingsoon/ComingSoon';
import ClientRev from './pages/ClientRev';





const Jsx = () => (
    <BrowserRouter>
        <Maincontext>
            <Routes>
                <Route path="/" element={<Body />}>
                    <Route path="/comingsoon" element={<ComingSoon />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/project/:project/:imgCount" element={<Project />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/clients" element={<ClientRev/>} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/ourclients" element={<OurClients />} />
                    <Route path="/*" element={<Error />} />
                </Route>
            </Routes>
        </Maincontext>
    </BrowserRouter>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Jsx />);

// this export default is nothing but used to reduse an error while building (not found default export on this file)
export default Jsx;
