import React, {useContext} from 'react';
import Base from '../../config';
import Context from '../utils/context/Context';

const Preloader = () => {
    const {wsmode} = useContext(Context);
    return (
        <div className="preloader all-center">
            <img className="preloader-gif" src={wsmode ? Base.preloader : Base.dpreloader} alt="preloader" />
        </div>
    );
}

export default Preloader;
