import React, { useEffect, useContext, useRef } from 'react';
import { Row, Col, Container } from 'react-grid-system';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Cursor from '../components/mouse/Cursor';
import "../css/clientRev.css";
import Base from '../../config';
import Context from '../utils/context/Context';
import Pagenum from '../components/pagecount/Pagenum';
import $ from 'jquery';

const ClientRev = () => {
  const { setpage, preloader, setpreloader } = useContext(Context);
  const carouselRef = useRef(null);

  const load = () => {
    setTimeout(() => {
      $('.contact-top').css({ left: "0" });
      $('.contact-second').css({ right: "0" });
    }, 100);
  }

  useEffect(() => {
    setpage("Clients");
    if (preloader === true) {        
      setTimeout(() => {
        setpreloader(false);
        load();
      }, 2000);
    } else {
      setpreloader(false);
      load();
    }
  }, []);

  const reviews = [
    {
      text: "Working with Amit Agrawal Ji was an absolute pleasure. From the initial consultation to the final walk-through, he demonstrated exceptional creativity, attention to detail, and professionalism. Our new home is not only beautiful but also incredibly functional. He listened to our needs and translated them into a design that exceeded our expectations. We receive compliments from everyone who visits!",
      author: "Krishna Dubey"
    },
    {
      text: "When we decided to open our new boutique, we knew we needed an architect who could create a unique and inviting space. Amit Agrawal delivered exactly what we envisioned and more. Their keen sense of style and understanding of our brand identity resulted in a stunning store layout that attracts customers and enhanced their shopping experience. Thanks to their expertise and dedication, the project was seamless from start to finish.",
      author: "Sailaja Sharma"
    },
    {
      text: "Our community is immensely grateful for the incredible work Amit Agrawal did on the library renovation. They respected the historical significance of the building while introducing modern elements that have revitalized the space. The thoughtful design has made the library more accessible and inviting for all visitors. The project was completed on time and within budget, which was a huge plus. He has truly made a lasting positive impact on our community.",
      author: "Siddharth Singhania"
    },

  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const CustomRightArrow = ({ onClick }) => {
    return <img src={Base.prev}  onClick={onClick} className="next-button" alt="Next" />;
  };

  const CustomLeftArrow = ({ onClick }) => {
    return <img src={Base.back} onClick={onClick} className="prev-button" alt="Previous" />;
  };

  return (
    <>
      <Cursor />
      <Pagenum num="06"/>
      <div className="container2" style={{ marginTop: "50px"}}>
        
          <Row justify='start'>
            <Col sm={12}>

              <h1 className='main-head' style={{padding:"20px"}}>Client reviews</h1>
            </Col>
          </Row>
       
{/*         
        <div className="left">
       
            <Row justify='start'>
              <Col md={6}>
                <p className='head-para' style={{marginBottom:"21px",padding:"20px",fontSize:"16px"}}>
                Our clients' testimonials speak volumes about our commitment to excellence and innovation in architecture.
                </p>

              </Col>
            </Row>
          
        </div> */}
        <div className="container-rev ">
          <Carousel
            ref={carouselRef}
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            customRightArrow={<CustomRightArrow />}
            customLeftArrow={<CustomLeftArrow />}
          >
            {reviews.map((review, index) => (
              <div key={index}>
              <Container>
                <Row>
                  <Col sm={12}>
                    <p className='head-para' style={{ fontStyle: "italic", fontSize: "16px", lineHeight: "1.6", margin: "0", textAlign: "left" ,fontFamily:"Neue Regrade"}}>
                      “ {review.text} <br /><strong>-{review.author}</strong>
                    </p>
                  </Col>
                </Row>     
                </Container>
              </div>
            ))}
          </Carousel>
          <div className="carousel-buttons">
            <img
              src={Base.prev}
              style={{borderRadius:"100px"}}
              onClick={() => carouselRef.current.previous()}
              className="prev-button"
              alt="Previous"
            />
            <img
              src={Base.back}
              onClick={() => carouselRef.current.next()}
              className="next-button"
              alt="Next"
              style={{borderRadius:"100px"}}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientRev;











