import SwarnbhoomiPlan1 from "../images/projects/plan1.png"
import SwarnbhoomiPlan2 from "../images/projects/plan2.png"
import SwarnbhoomiPlan3 from "../images/projects/plan3.png"
import BhajjankaPlan1 from "../images/projects/bhajjanka-plan-1.jpeg"
import BhajjankaPlan2 from "../images/projects/bhajjanka-plan-2.jpeg"
import BhajjankaPlan3 from "../images/projects/bhajjanka-plan-3.jpeg"

const ProjectPlanImg = {
    'swarnbhoomi-plan-1': SwarnbhoomiPlan1,
    'swarnbhoomi-plan-2': SwarnbhoomiPlan2,
    'swarnbhoomi-plan-3': SwarnbhoomiPlan3,

    'bhajjankas-mansions-plan-1': BhajjankaPlan1,
    'bhajjankas-mansions-plan-2': BhajjankaPlan2,
    'bhajjankas-mansions-plan-3': BhajjankaPlan3,
};

export default ProjectPlanImg;