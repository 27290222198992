import React, { useContext, useEffect } from 'react';
import Cursor from '../components/mouse/Cursor';
import Context from '../utils/context/Context';
import { Row, Col } from 'react-grid-system';
import Pagenum from '../components/pagecount/Pagenum';
import $ from 'jquery';

// client logs
import Hira from '../images/clients/hira.png';
import Nakoda from '../images/clients/nakoda.png';
import Ans from '../images/clients/ans.png';
import Rktc from '../images/clients/rktc.png';
import Gudakhu from '../images/clients/gudakhu.png';

const OurClients = () => {
    const { preloader, setpreloader, setpage } = useContext(Context);

    const load = () => {
        setTimeout(() => {
            $('.imgcard-left').css({ left: "0vw" });
            $('.imgcard-right').css({ right: "0vw" });
        }, 100);
    }

    useEffect(() => {
        setpage("clients");
        if (preloader === true) {
            setTimeout(() => {
                setpreloader(false);
                load();
            }, 2000);
        } else {
            setpreloader(false);
            load();
        }
    }, []);
    return (
        <div>
            <Cursor />
            <Pagenum num="03"/>
            <h2 className='page-head imgcard-left'>Corporate Clients</h2>

            {/* <div className='clients-box'> */}
                <Row>
                    <Col sm={3}>
                        <img className='w-100 imgcard imgcard-left' src={Hira} alt='banglo' />
                    </Col>
                    <Col sm={3}>
                        <img className='w-100 imgcard imgcard-left' src={Ans} alt='banglo' />
                    </Col>
                    <Col sm={3}>
                        <img className='w-100 imgcard imgcard-right' src={Nakoda} alt='banglo' />
                    </Col>
                    <Col sm={3}>
                        <img className='w-100 imgcard imgcard-right' src={Gudakhu} alt='banglo' />
                    </Col>
                    <Col sm={3}>
                        <img className='w-100 imgcard imgcard-left' src={Rktc} alt='banglo' />
                    </Col>
                </Row>
            {/* </div> */}


        </div>
    );
}

export default OurClients;
