import React, { useContext, useEffect } from 'react';
import parse from 'html-react-parser';
import Context from '../utils/context/Context';
import { useParams } from 'react-router-dom';
import Cursor from '../components/mouse/Cursor';
import { Row, Col, Container } from 'react-grid-system';
import Pimg from '../data/Pimg';
import ProjectPlanImg from '../data/ProjectPlanImg';
import $ from 'jquery';
import "../css/project.css"
// import plan1 from "../images/projects/plan1.png"
// import plan2 from "../images/projects/plan2.png"
// import plan3 from "../images/projects/plan3.png"

import Base from '../../config';
import amit from "../images/amitlogo.svg";
import ProjectDataList from '../data/ProjectDataList';


const Project = () => {

    const { preloader, setpreloader } = useContext(Context);
    const { project, imgCount } = useParams();


    const imgArray = Array.from({ length: imgCount }, (_, index) => index + 1);
    const PlanImgArray = Array.from({ length: 3 }, (_, index) => index + 1);

    const title = project.replace(/_/g, ' ');
    const arrayProject = ProjectDataList.filter(p => p.link == project + '/' + imgCount);
    console.log(arrayProject);
    const load = () => {
        setTimeout(() => {
            $('.projectcard-left').css({ left: "0vw" });
            $('.projectcard-right').css({ right: "0vw" });
        }, 100);
    }

    useEffect(() => {
        if (preloader === true) {
            setTimeout(() => {
                setpreloader(false);
                load();
            }, 2000);
        } else {
            setpreloader(false);
            load();
        }
    }, []);
    return (
        <div>



            <div className="container gaurang w-100">
                <img src={Base.main} alt="" srcset="" className='w-100' />
            </div>
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="bhoomi">
                            <h1 className='main-head'>{arrayProject.map(p => p.name)}</h1>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row>

                    <Col md={7}>
                        <p className="dummy-text ">
                            {arrayProject.map(p => p.description)}
                        </p>
                    </Col>
                </Row>
            </Container>



            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="bhoomi">

                            <h1 className='main-head'>Technical description</h1>
                        </div>
                    </Col>
                </Row>
            </Container>


            <Container>
                <Row>

                    <Col md={7}>
                        <p className="dummy-text head-para">
                        {arrayProject.map(p => p.tech_description)}
                        </p>
                    </Col>
                </Row>
            </Container>





            <Container>
                <Row>
                    <Col sm={6}>
                        <div className="flex justify-between">
                            <div>
                                <h4 className='main-head' style={{ fontSize: "21px" }}>Area</h4>
                                <p style={{ fontSize: "16px" }} className='head-para'>
                                {arrayProject.map(p => p.area)}
                                </p>
                            </div>
                            <div>
                                <h4 className='main-head' style={{ fontSize: "21px" }}>Architect</h4>
                                <p style={{ fontSize: "16px" }} className='head-para'>
                                {arrayProject.map(p => p.architect)}
                                </p>
                            </div>
                            <div>
                                <h4 className='main-head' style={{ fontSize: "21px" }}>Year</h4>
                                <p style={{ fontSize: "16px" }} className='head-para'>
                                {arrayProject.map(p => p.year)}
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>


            <div className="details">
                <Container>
                    <h1 className='main-head'>Details</h1>
                </Container>
                <Container className="desc ">
                    <ul className='head-para'>
                        {parse(`${arrayProject.map(p => p.details_list_data)}`)}
                    </ul>
                </Container>
            </div>




            <div>
                <Container>
                    <Row justify='start'>
                        {PlanImgArray.map((index) => (
                            <Col sm={4} key={index} className='custom'>
                                <div className="container-hover">
                                    <img src={ProjectPlanImg[`${project}-plan-${index}`]} alt="planbanglo" srcset="" />
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
            <Container>
                <div className="container">
                    <div className="heading">
                        <Container>
                            <Row>
                                <h1 className='main-head'>Images</h1>
                            </Row>
                        </Container>
                    </div>
                </div>
            </Container>




            <Cursor />
            <Container>
                <Row justify='center'>
                    <Col sm={6} lg={12}>
                        <Row justify='start'>
                            {imgArray.map((index) => (
                                <Col md={6} key={index}>
                                    <img className={index % 2 === 0 ? "w-100 imgcard projectcard-right" : "w-100 imgcard projectcard-left"} src={Pimg[`${project}-${index}`]} alt='banglo' />
                                    <img className="watermark" src={amit} />
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>


        </div>
    );
}
export default Project
