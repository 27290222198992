import React, { useEffect, useState } from "react";
import $ from 'jquery';
import Context from "./Context";
import { useNavigate } from 'react-router-dom';

const maincontext = (props) => {
  const navigate = useNavigate();
  const vwidth = window.innerWidth;
  const vheight = window.innerHeight;


  // port
  const [port, setport] = useState("no port data");
  const [wsmode, setwsmode] = useState(true);

  const setMode = () => {
    if (wsmode) {
      localStorage.setItem('amitmode', 'Dark');
      root.style.cssText = '--bgc: #121212; --p-tc: #e6e6e6; --bc: #cdcdcd; --mh-tc: #e6e6e6; --place-tc: #CDCDCD; --s-tc: #cdcdcd; --c-tc:#e6e6e6;';
    } else {
      localStorage.setItem('amitmode', 'Light');
      root.style.cssText = '--bgc: #f9f9f9; --p-tc: #0d0d0d; --bc: #000000; --mh-tc: #000000; --place-tc: #3f3f3f; --s-tc: #727272; --c-tc:#2b2b2b;';
    }
  }

  // mobile responsive
  const [responsive, setresponsive] = useState(false);

  // preloader
  const [preloader, setpreloader] = useState(true);

  // header some 
  const [menu, setmenu] = useState(false)
  const [page, setpage] = useState("")
  // header some ends

  // reset pos
  const resetpos = (page) => {
    const home = () => {
      setTimeout(() => {
        $('.home-left').addClass("pos-right");
        $('.headset').addClass("pos-left");
        $('.mob-headset').css({ left: "-100vw" });
        $('.mob-counter').css({ right: "-100vw" });
        $('.mob-banner').css({ right: "-100vw" });
      }, 300);
    }

    const about = () => {
      setTimeout(() => {
        $('.about-left').css({ left: "-100vw" });
        $('.about-right').css({ right: "-100vw" });
      }, 300);
    }

    const project = () => {
      setTimeout(() => {
        $('.page-direction').addClass("pos-bottom");
        $('.projects-section').css({ right: "-100vw" });
        $('.mob-project-left').css({ left: "-100vw" });
        $('.mob-project-right').css({ right: "-100vw" });
      }, 300);
    }

    const clients = () => {
      setTimeout(() => {
        $('.imgcard-left').css({ left: "-100vw" });
        $('.imgcard-right').css({ right: "-100vw" });
      }, 300);
    }

    const pro = () => {
      setTimeout(() => {
        $('.projectcard-left').css({ left: "-100vw" });
        $('.projectcard-right').css({ right: "-100vw" });
      }, 300);
    }

    const contact = () => {
      setTimeout(() => {
        $('.contact-top').css({ left: "-100vw" });
        $('.contact-second').css({ right: "-100vw" });
      }, 300);
    }

    switch (page) {
      case "home":
        // console.log("Option A selected");
        about();
        project();
        contact();
        clients();
        pro();
        break;
      case "projects":
        // console.log("Option B selected");
        home();
        about();
        contact();
        clients();
        pro();
        break;
      case "about":
        // console.log("Option C selected");
        home();
        project();
        contact();
        clients();
        pro();
        break;
      case "contact":
        // console.log("Option C selected");
        home();
        about();
        project();
        clients();
        pro();
        break;
      case "ourclients":
        // console.log("option client");
        home();
        about();
        project();
        contact();
        pro();
        break;
      default:
        console.log("page not found for animation, set this problem to add switch in mainContext");
        
    }

    setTimeout(() => {
      navigate(page);
    }, 800);
  };

  const modeCheck = () => {
    var mstate = localStorage.getItem('amitmode');
    // console.log(mstate);
    switch (mstate) {
      case "Light":
        setwsmode(true);
        root.style.cssText = '--bgc: #f9f9f9; --p-tc: #0d0d0d; --bc: #000000; --mh-tc: #000000; --place-tc: #3f3f3f; --s-tc: #727272; --c-tc:#2b2b2b;';
        break;
      case "Dark":
        setwsmode(false);
        root.style.cssText = '--bgc: #121212; --p-tc: #e6e6e6; --bc: #cdcdcd; --mh-tc: #e6e6e6; --place-tc: #CDCDCD; --s-tc: #cdcdcd; --c-tc:#e6e6e6;';
        break;
      default:
        localStorage.setItem('amitmode', 'Light');
        setwsmode(true);
        root.style.cssText = '--bgc: #f9f9f9; --p-tc: #0d0d0d; --bc: #000000; --mh-tc: #000000; --place-tc: #3f3f3f; --s-tc: #727272; --c-tc:#2b2b2b;';
    }
  }

  useEffect(() => {
    // modeCheck();
    if (vwidth < 767) {
      setresponsive(true);
    }
  }, []);

  return (
    <Context.Provider value={{ port, setport, vwidth, vheight, menu, setmenu, page, setpage, resetpos, preloader, setpreloader, responsive, setresponsive, wsmode, setwsmode, setMode }}>
      {props.children}
    </Context.Provider>
  )
}
export default maincontext;