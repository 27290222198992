import React, { useState, useEffect } from 'react';
import './coming.css';

const ComingSoon = () => {
  // Set your target launch date here (in UTC)
  const launchDate = new Date('2024-03-05T00:00:00Z');

  // Calculate the remaining time until launch
  const calculateTimeUntilLaunch = () => {
    const now = new Date();
    const timeDifference = launchDate - now;

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  // State to hold the remaining time
  const [timeUntilLaunch, setTimeUntilLaunch] = useState(calculateTimeUntilLaunch());

  // Update the remaining time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeUntilLaunch(calculateTimeUntilLaunch());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="coming-soon-container">
      <h1>Amit Agrawal</h1>
      <p>A new portfolio is on the way!</p>
      <p>But you can still reach out to me by sending an email to <a href="mailto:amitagrawal@gmail.com">amitagrawal@gmail.com</a></p>
      <div className="countdown">
        <p>Launches in : 
          {" "}{timeUntilLaunch.days} days, {timeUntilLaunch.hours} hours, {timeUntilLaunch.minutes} minutes,{' '}
          {timeUntilLaunch.seconds} seconds
        </p>
      </div>
    </div>
  );
};

export default ComingSoon;
