import Base from '../../config';

const ProjectDataList =
    [
        {
            "id": 1,
            "name": "Bhajjanka's Mansion",
            "place": "Raipur",
            "year": "2022",
            // "link": "bhajjankas-mansions/18",
            "image_path": Base.b1,
            "description": "",
            "tech_description": "Each project is crafted to meet the highest standards of quality, efficiency, and regulatory compliance.",
            "area": "140ft × 100ft",
            "architect": "Amit Agrawal & Associates",
            'details_list_data': '<li>The residence draws inspiration from the balanced proportions and symmetry inherent in Neoclassical architecture. This is achieved through a well-defined central block flanked by two identical wings, creating a sense of formality and order.</li><li>Designed for a multi-generational family, the residence comprises three interconnected living spaces, each catering to a specific family unit. This fosters a sense of togetherness while ensuring individual privacy within the shared home.</li><li>The corner plot site presented a unique opportunity to create elevations on all four sides of the residence. This not only ensures ample natural light and ventilation throughout the interiors but also allows for a more architecturally complete and visually interesting structure from every vantage point.</li><li>Designed and constructed during the unprecedented challenges of the COVID-19 pandemic, the project demanded innovative solutions and careful planning to ensure the safety of workers and adhere to social distancing guidelines.</li><li>Incorporating the ancient Indian architectural principles of Vastu Shastra, the spatial organization promotes harmony and well-being for the residents. This is achieved through careful placement of rooms, use of natural materials, and alignment with cardinal directions.</li><li>The 14,000-square-foot site provides ample space for the tripartite layout. Each section of the residence has been designed to accommodate the specific needs of each family group, while the remaining area has been dedicated to shared spaces, landscaped gardens, or recreational facilities.</li>'
        },
        {
            "id": 2,
            "name": "Swarnbhoomi 2",
            "place": "Raipur",
            "year": "2022",
            "link": "swarnbhoomi/15",
            "image_path": Base.b2,
            "description": "Here, the first floor has a total area of 6,219 square feet. It is split across three blocks: A, B and C. Block A has an area of 2,030 square feet and a covered balcony of 308 square feet. Block B comprises a built-up area of 1,461 square feet and a covered balcony area of 443 square feet whereas Block C features a built-up area of 1,703 square feet and a covered balcony area of 274 square feet.",
            "tech_description": "Each project is crafted to meet the highest standards of quality, efficiency, and regulatory compliance.",
            "area": "140ft × 100ft",
            "architect": "Amit Agrawal & Associates",
            'details_list_data': '<li>The residence draws inspiration from the balanced proportions and symmetry inherent in Neoclassical architecture. This is achieved through a well-defined central block flanked by two identical wings, creating a sense of formality and order.</li><li>Designed for a multi-generational family, the residence comprises three interconnected living spaces, each catering to a specific family unit. This fosters a sense of togetherness while ensuring individual privacy within the shared home.</li><li>The corner plot site presented a unique opportunity to create elevations on all four sides of the residence. This not only ensures ample natural light and ventilation throughout the interiors but also allows for a more architecturally complete and visually interesting structure from every vantage point.</li><li>Designed and constructed during the unprecedented challenges of the COVID-19 pandemic, the project demanded innovative solutions and careful planning to ensure the safety of workers and adhere to social distancing guidelines.</li><li>Incorporating the ancient Indian architectural principles of Vastu Shastra, the spatial organization promotes harmony and well-being for the residents. This is achieved through careful placement of rooms, use of natural materials, and alignment with cardinal directions.</li><li>The 14,000-square-foot site provides ample space for the tripartite layout. Each section of the residence has been designed to accommodate the specific needs of each family group, while the remaining area has been dedicated to shared spaces, landscaped gardens, or recreational facilities.</li>',
        },
        {
            "id": 3,
            "name": "Corporate Clients",
            "place": "Bilaspur",
            "year": 2024,
            "link": "",
            "image_path": Base.b3
        },
    ]

export default ProjectDataList;
// "link": "bhajjankas-mansions",
// "link": "Corporate_Clients/4"